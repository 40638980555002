import React from "react";
import photo from "./adam.jpg";
import "./style.css";

const Header = props => {
  return (
    <header className="header">
      <img src={photo} className="header__img" />
      <div className="header__right">
        <h1 className="header__name">Adam Baialiev</h1>
        <span className="header__role">React/React Native Developer</span>
        <a className="header__email" href="mailto: adam.baialiev@gmail.com">
          adam.baialiev@gmail.com
        </a>
        <a
          className="header__email"
          href="https://gist.github.com/adambaialiev"
          target="_blank"
        >
          Code samples on GitHub Gist
        </a>
      </div>
    </header>
  );
};

export default Header;
