import React, { Component } from "react";
import "./App.css";
import Header from "./components/header/header";
import WorkExperience from "./components/work-experience/work-experience";
import Technologies from "./components/technologies/technologies";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <WorkExperience />
        <Technologies />
        <code>Remote location: Bishkek, Kyrgyz Republic (GMT+6)</code>
      </div>
    );
  }
}

export default App;
