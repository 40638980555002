export default [
  {
    companyName: "Flyerbee AG",
    companyLocation: "Zurich, Switzerland",
    role: "React Native Developer",
    period: "July 2019 - present",
    jobs: [
      {
        text:
          "The purpose of the app is to help local companies to effectively distribute and manage outdoor advertising materials"
      },
      {
        text:
          "My role is to develop new major features. Some of them can be watched in this video:",
        videoId: "HewWBexLKJQ"
      }
    ]
  },
  {
    companyName: "Yurtah Inc.",
    companyLocation: "USA, New York",
    role: "Frontend React/React Native Developer",
    period: "Oct 2018 - Apr 2019",
    jobs: [
      {
        text:
          "Developed Server-side rendered React app. Used Node.js and Docker."
      },
      {
        text: "Some features in action can be watched here:",
        videoId: "XnJnIvV4IvQ"
      },
      {
        text:
          "Due to the server-side nature, the app is SEO-friendly. Used React Helmet for head rendering."
      },
      {
        text:
          "Actively used Test-Driven-Development using Jest and React-testing-library. Test coverage was above 50%."
      },
      {
        text:
          "Used Redux for state management. Redux-saga for side effects. SCSS for styles."
      },
      {
        text:
          "The target audience of the app is real estate professionals in New York city. Agents can post their listings with photos, videos, documents and other information."
      },
      {
        text:
          "Website guests can scroll through listings, search, filter, go to detailed pages. Watch objects on map. "
      },
      {
        text:
          "Broker can manage his calender inside the app, where he can choose appropriate time for objects to be visited. Guests can book certain time for visit."
      },
      {
        text:
          "Developed iOS app using Swift with UIKit, CoreLocation, MapKit. Used architectures like MVC, MVVM, Viper. As a result, decided to go further with React Native, because it is much more productive and cleaner."
      },
      {
        text:
          "Developer iOS app using React Native. Some features in action can be watched here:",
        videoId: "vvkCOU5J3vM"
      },
      {
        text:
          "In this app user can watch objects on the app, or clusters of objects. If a user taps a clusters, modal window is opened with the content of the cluster. If a users taps an object, he is transitioned to the detail page. Also other features are developed like add to favorites, list of favortes, profile, authorization, registration."
      }
    ]
  },
  {
    companyName: "Sky Mobile Inc.",
    companyLocation: "Bishkek, Kyrgyz Republic",
    role: "Frontend React Developer",
    period: "Aug 2018 - Oct 2018",
    jobs: [
      {
        text:
          "Developed the app where beauty service professionals can manage their bookings. They can add new booking by themselves, or it can be automitically added from external source. He can share a special link to his clients."
      },
      {
        text:
          "Developed the app where user can watch available time of a beauty professional and book it."
      }
    ]
  },
  {
    companyName: "Heartbeat Technologies",
    companyLocation: "Canada, Toronto",
    role: "Frontend React Developer",
    period: "Apr 2018 - Oct 2018",
    jobs: [
      {
        text:
          "Developed the analytics app with a lot of D3.js charts, search and filtering. A lot of async actions. All business logic was run on redux-saga."
      }
    ]
  },
  {
    companyName: "Attractor School",
    companyLocation: "Bishkek, Kyrgyz Republic",
    role: "Javascript Mentor",
    period: "Sept 2017 - Apr 2018",
    jobs: [
      {
        text:
          "I was a mentor for 40 students. Answered questions, explained technical topics, checked homeworks."
      }
    ]
  }
];
