import React from "react";
import "./style.css";
import data from "../../work-experience-data";
import YouTube from "react-youtube";

const WorkExperience = props => {
  return (
    <div className="work-experience">
      <h1 className="work-experience__title">WORK EXPERIENCE</h1>
      <div className="work-experience__inner">
        {data.map((d, i) => (
          <section className="work-experience__item">
            <div className="work-experience__item-head">
              <div className="work-experience__item-head-top">
                <h3 className="work-experience__company-name">
                  {d.companyName}
                </h3>
                <span className="work-experience__role">{d.role}</span>
              </div>
              <span className="work-experience__period">
                {d.period} | {d.companyLocation}
              </span>
            </div>
            <ul className="work-experience__item-body">
              {d.jobs.map(job => {
                return (
                  <li>
                    {job.text}
                    {job.videoId && (
                      <YouTube
                        containerClassName="videoWrapper"
                        videoId={job.videoId}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </section>
        ))}
      </div>
    </div>
  );
};

export default WorkExperience;
