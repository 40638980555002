import React from "react";
import "./style.css";

const Technologies = props => {
  return (
    <section className="technologies">
      <h1 className="technologies__title">TECHNOLOGIES USED:</h1>
      <ul className="technologies__body">
        <li className="technologies__item">
          React, Redux, Redux saga, GraphQL
        </li>
        <li className="technologies__item">React Native, React Navigation</li>
        <li className="technologies__item">Swift, Objective C</li>
        <li className="technologies__item">SCSS, CSS-in-JS</li>
        <li className="technologies__item">Webpack, Babel</li>
        <li className="technologies__item">
          Node.js, Express.js, Docker, Docker-compose
        </li>
        <li className="technologies__item">Git, GitFlow</li>
      </ul>
    </section>
  );
};

export default Technologies;
